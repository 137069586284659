import Place from "./Place";
export default class Trip {
    public from!: Place;
    public to!: Place;

    public constructor(init?:Partial<Trip>) {
        Object.assign(this, init);
        if(init){
          if(init.from){
            this.from = new Place(init.from);
          }
          if(init.to){
            this.to = new Place(init.to);
          }
        }
    }

    public isSameLocation():boolean{
      try{
        if(this.from && this.to){
          if(this.from.formatted_address == this.to.formatted_address){
            return true;
          }
          //components breakdown check by country, state and postal code
          let fromComponents = {} as any;
          if(this.from.address_components && this.from.address_components.constructor == Array) {
            for (let i = 0; i < this.from.address_components.length; i++) {
              let component = this.from.address_components[i];
              let type = component.types[0];
              switch (type) {
                case 'postal_code':
                  fromComponents.postalCode = component.long_name;
                  break;
                case 'country':
                  fromComponents.countryCode = component.short_name;
                  break;
                case 'administrative_area_level_1':
                  fromComponents.state = component.short_name;
                  break;
                default:
                  break;
              }
            }
          }
          let toComponents = {} as any;
          if(this.to.address_components && this.to.address_components.constructor == Array) {
            for (let i = 0; i < this.to.address_components.length; i++) {
              let component = this.to.address_components[i];
              let type = component.types[0];
              switch (type) {
                case 'postal_code':
                  toComponents.postalCode = component.long_name;
                  break;
                case 'country':
                  toComponents.countryCode = component.short_name;
                  break;
                case 'administrative_area_level_1':
                  toComponents.state = component.short_name;
                  break;
                default:
                  break;
              }
            }
          }
          if(fromComponents.postalCode == toComponents.postalCode && fromComponents.countryCode == toComponents.countryCode && fromComponents.state == toComponents.state){
            return true;
          }
        }
        return false;
      }catch (e){
        return false;
      }
    }
    public updateFormattedAddressLabels(){
      try{
        if(this.from)
          this.from.formatLabel(this.from, false);
        if(this.to)
          this.to.formatLabel(this.to, false);
      }catch (e){
        return;
      }
    }
}
interface ComponentDefI{
  long_name: string,
  short_name: string
}

interface ComponentI {
  street_number:ComponentDefI,
  route:ComponentDefI,
  intersection:ComponentDefI,
  political:ComponentDefI,
  country:ComponentDefI,
  administrative_area_level_1:ComponentDefI, 
  administrative_area_level_2:ComponentDefI,
  administrative_area_level_3:ComponentDefI,
  administrative_area_level_4:ComponentDefI,
  administrative_area_level_5:ComponentDefI,
  administrative_area_level_6:ComponentDefI,
  administrative_area_level_7:ComponentDefI,
  colloquial_area:ComponentDefI,
  locality:ComponentDefI,
  sublocality:ComponentDefI,
  sublocality_level_1:ComponentDefI,
  sublocality_level_2:ComponentDefI,
  sublocality_level_3:ComponentDefI,
  sublocality_level_4:ComponentDefI,
  sublocality_level_5:ComponentDefI,
  neighborhood:ComponentDefI,
  premise:ComponentDefI,
  subpremise:ComponentDefI,
  plus_code:ComponentDefI,
  postal_code:ComponentDefI,
  natural_feature:ComponentDefI,
  airport:ComponentDefI,
  park:ComponentDefI,
  floor:ComponentDefI,
  establishment:ComponentDefI, 
  landmark:ComponentDefI,
  point_of_interest:ComponentDefI,
  parking:ComponentDefI,
  post_box:ComponentDefI,
  postal_town:ComponentDefI,
  room:ComponentDefI,
  bus_station:ComponentDefI,
  train_station:ComponentDefI,
  transit_station:ComponentDefI,
}
